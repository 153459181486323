.layout {
  padding: 0 3rem 0 3rem
}

body {
  font-family: 'Open Sans';
}

h1, h2, h3, h4, h5, h6 {
  display: inline-block;
}

/* hack to override the calendar */
.DateInput input {
  padding-top: 23px;
  padding-bottom: 6px;
  padding-right: 12px;
  padding-left: 12px;
  margin: 0;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.DateInput {
  width: 107px !important;
}

.DateRangePickerInput_arrow {
  padding-top: 23px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin: 0;
  border: 0;
}

.DateRangePicker {
  width: 100%;
  background-color: white;
}

.DateRangePicker_picker {
  z-index: 2 !important;
}