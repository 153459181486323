/*
* Simple hack to overwrite position when responsive
*/
.js-plotly-plot .plotly .svg-container .main-svg:first-child {
  position: relative !important;
}

/*
* Simple hack to overwrite text
*/
.cbaxis.crisp > g > text {
  width: 62px;
  height: 14px;
  color: rgb(216, 216, 216) !important;
  fill: rgb(216, 216, 216) !important;
  font-size: 14px !important;
  font-family: 'Open Sans !important';
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 14px;
}

.cbtitle > text {
  width: 69px;
  height: 42px;
  color: rgb(42, 42, 42) !important;
  fill: rgb(42, 42, 42) !important;
  font-size: 14px !important;
  font-family: 'Open Sans !important';
  font-weight: 600 !important;
  letter-spacing: 0px;
  line-height: 14px;
}

.updatemenu-item-rect {
  fill: #424242 !important;
  fill-opacity: 1 !important;
}

.updatemenu-item-rect:hover {
  fill-opacity: 0.7 !important;
}